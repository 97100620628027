import { useEffect, useState } from 'react'
import Head from 'next/head'

import { getDomainName, getRootDomain, isCustomDomain, isDevelopEnvironment, isRootDomain } from '@utils/index'
import Welcome from '@components/Welcome'
const favicon = '/assets/favicon/favicon.png'

const Home = () => {
  const [hostName, setHostName] = useState('')

  const valdiateCustomDomainRedirection = () => {
    const currentHost = window.location.hostname
    setHostName(getDomainName(currentHost))
    if (!isDevelopEnvironment(currentHost) && isCustomDomain(currentHost) && !isRootDomain(currentHost)) {
      window.location.href = getRootDomain(currentHost)
    }
  }

  useEffect(() => {
    valdiateCustomDomainRedirection()
  }, [])

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <title>Home - {hostName}</title>
        <link rel='shortcut icon' type='image/png' href={favicon} />
        <meta name='robots' content='noindex' />
      </Head>
      <Welcome />
    </>
  )
}

export default Home
