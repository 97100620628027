export const THEMES = {
  ROCHE: 'roche',
  ORACLE: 'oracle',
  SENDSPARK: 'sendspark',
  SENSDPARK_LEGACY: 'sendspark-legacy',
};

export const ANALYTICS_TITLE = {
  CampaignView: 'Campaign Viewed',
  CampaignVideoPlay: 'Campaign Video Played',
  CampaignResponseClick: 'Campaign Response Clicked',
  CampaignCtaClick: 'Campaign CTA Clicked',
  CampaignVideoEnded: 'Campaign Video Ended',
  ViralLinkClick: 'Viral Link Clicked',
  CampaignVideoLike: 'Campaign Video Liked',
  VideoViewReceived: 'Video View Received',
  ViewGot: 'View Got',
  CtaCovensionGot: 'Video CTA Conversion Received',
};

export const SCRIPT_GENERATOR_TABS = {
  SCRIPT: 'script',
  EMAIL: 'email',
};

export const MUX_STATUS = {
  PREPARING: 'preparing',
  READY: 'ready',
};

export const COOKIE_KEYS = {
  CURRENT_WORKSPACE: 'current_workspace',
  USER_ID: 'user_id',
  SENDSPARK_JWT: 'sendspark_jwt',
  WORKOS_SESSION: 'wos-session',
};

export const LOCAL_STORAGE_KEYS = {
  USER_SESSION_ID: 'aVfW9qLwQVTO',
};

export enum SharePreviewTypes {
  Template = 'template',
  Campaign = 'campaign',
  DynamicVideo = 'dynamic-video',
  Workspace = 'workspace',
}

export const CALENDAR_PROVIDERS = {
  LEMCAL: 'lemcal-embed-booking-calendar',
}

export const CALENDAR_CUSTOM_LAYOUTS = {
  [CALENDAR_PROVIDERS.LEMCAL]: {
    Right: 'LemcalRight',
    Main: 'LemcalMain',
  },
}