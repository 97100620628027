import styled from 'styled-components'

const WelcomeStyles = styled.div`
  font-family: 'Eina 01 Bold',san-serif;
  font-size: 100px;
  letter-spacing: 3px;
  color: #dadada;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default WelcomeStyles